<template>
  <b-card title="Letra">
    {{ campanha.letra }}
  </b-card>
</template>

<script>
import {
  BCard, BImg, BMedia, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
  },
  props: {
    campanha: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>

</style>
