<template>
  <b-card
    no-body
  >

    <b-card-body>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="success"
        block
        @click="aprovarCampanha"
      >
        Aprovar
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-danger"
        block
        @click="reprovarCampanha"
      >
        Reprovar
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import useJwt from '@/auth/jwt/useJwt'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  props: {
    campanha: {
      type: Object,
      required: true,
    },
  },
  methods: {

    // APROVAR CAMPANHA
    aprovarCampanha() {
      this.$swal({
        title: 'Deseja aprovar essa campanha?',
        text: 'Confirme a aprovação somente se a campanha estiver com toda as informações válidas.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, aprovar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          // API
          useJwt.campanhaAprovar({
            id: this.campanha.id,
            statusAprovacaoCampanha: 1,
          })
            .then(response => {

              this.$swal({
                title: 'Campanha Aprovada!',
                text: 'Essa campanha ficará visível para todos os usuários.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then(result => {
                if (result.isConfirmed) {
                  // REDIRECIONA PARA APROVAÇÕES
                  this.$router.push({ name: 'aprovacoes-campanha' })
                } else {
                  // REDIRECIONA PARA APROVAÇÕES
                  this.$router.push({ name: 'aprovacoes-campanha' })
                }
              })
            })
            .catch(error => {
              this.$swal({
                title: 'Ops! Aconteceu um erro.',
                text: error.response.data.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
          // API
        } else {

          // CANCELAR SWEET

        }
      }).catch(error => {

      })
    },
    // APROVAR CAMPANHA

    // REPROVAR HIT
    reprovarCampanha() {
      this.$swal({
      title: 'Deseja reprovar essa campanha?',
      text: 'Informe o motivo da reprovação.',
      icon: 'warning',
      html:
        '<div class="swal2-row">' +
        '<input id="swal-input1" class="swal2-input" placeholder="Digite o motivo">' +
        '<select id="swal-select1" class="swal2-input">' +
        '<option value="" disabled selected>Selecione um motivo</option>' +
        '<option value="2">Reprovado Geral</option>' +
        '<option value="3">Reprovado de Informações</option>' +
        '<option value="4">Reprovação de Imagem</option>' +
        '</select>' +
        '</div>',
      showCancelButton: true,
      confirmButtonText: 'Sim, reprovar!',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
      preConfirm: () => {
        const inputText = document.getElementById('swal-input1').value;
        const selectValue = document.getElementById('swal-select1').value;
        return {
          inputText,
          selectValue,
        };
      },
    }).then(result => {
        if (result.isConfirmed) {

          if (result.value.selectValue || result.value.inputText) {
            // API
            useJwt.campanhaReprovar({
              id: this.campanha.id,
              statusAprovacaoCampanha: result.value.selectValue,
              motivoStatus: result.value.inputText, // PUXAR VALOR DO INPUT DO SWAL
            })
              .then(response => {

                this.$swal({
                  title: 'Campanha Reprovada!',
                  text: 'O criador receberá uma notificação sobre a reprovação.',
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.isConfirmed) {
                    // REDIRECIONA PARA APROVAÇÕES
                    this.$router.push({ name: 'aprovacoes-campanha' })
                  } else {
                    // REDIRECIONA PARA APROVAÇÕES
                    this.$router.push({ name: 'aprovacoes-campanha' })
                  }
                })
              })
              .catch(error => {
                this.$swal({
                  title: 'Ops! Aconteceu um erro.',
                  text: error.response.data.message,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
            // API
          } else {
            this.$swal({
              title: 'Ops! Aconteceu um erro.',
              text: 'Preencha o motivo da reprovação.',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        } else {

          // CANCELAR SWEET

        }
      }).catch(error => {

      })
    },
    // REPROVAR HIT

    // // REPROVAR CAMPANHA
    // reprovarCampanha() {
    //   this.$swal({
    //     title: 'Deseja reprovar essa campanha?',
    //     text: 'Informe o motivo da reprovação.',
    //     input: 'text',
    //     inputPlaceholder: 'Digite o motivo',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Sim, reprovar!',
    //     cancelButtonText: 'Cancelar',
    //     customClass: {
    //       confirmButton: 'btn btn-danger',
    //       cancelButton: 'btn btn-outline-danger ml-1',
    //     },
    //     buttonsStyling: false,
    //   }).then(result => {
    //     if (result.isConfirmed) {
    //       if (result.value) {
    //         // API
    //         useJwt.campanhaReprovar({
    //           id: this.campanha.id,
    //           statusAprovacaoCampanha: 2,
    //           motivoStatus: result.value, // PUXAR VALOR DO INPUT DO SWAL
    //         })
    //           .then(response => {
    //           // console.log( response );

    //             this.$swal({
    //               title: 'Campanha Reprovada!',
    //               text: 'O criador receberá uma notificação sobre a reprovação.',
    //               icon: 'error',
    //               customClass: {
    //                 confirmButton: 'btn btn-primary',
    //               },
    //               buttonsStyling: false,
    //             }).then(result => {
    //               if (result.isConfirmed) {
    //                 // REDIRECIONA PARA APROVAÇÕES
    //                 this.$router.push({ name: 'aprovacoes-campanha' })
    //               } else {
    //                 // REDIRECIONA PARA APROVAÇÕES
    //                 this.$router.push({ name: 'aprovacoes-campanha' })
    //               }
    //             })
    //           })
    //           .catch(error => {
    //             this.$swal({
    //               title: 'Ops! Aconteceu um erro.',
    //               text: error.response.data.message,
    //               icon: 'error',
    //               customClass: {
    //                 confirmButton: 'btn btn-primary',
    //               },
    //               buttonsStyling: false,
    //             })
    //           })
    //         // API
    //       } else {
    //         this.$swal({
    //           title: 'Ops! Aconteceu um erro.',
    //           text: 'Preencha o motivo da reprovação.',
    //           icon: 'error',
    //           customClass: {
    //             confirmButton: 'btn btn-primary',
    //           },
    //           buttonsStyling: false,
    //         })
    //       }
    //     } else {

    //       // CANCELAR SWEET

    //     }
    //   }).catch(error => {

    //   })
    // },
    // // APROVAR CAMPANHA

  },
}
</script>

<style>

.swal2-input {
  margin-top: 15px;
}

</style>
