<template>
  <b-card class="mb-2">

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">

          <div class="d-flex flex-column">
            <div class="mb-1">
              <h5 class="mb-0">
                {{ campanha.nome }}
              </h5>
              <span class="card-text">Criador: {{ campanha.usuarioCompradorId }}</span>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex mt-2">

          <!-- <vue-audio :file="hit.arquivo"></vue-audio> -->

        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >

        <!-- User Stats -->
        <div class="d-flex mt-0">
          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ formatarData(campanha.validade) }}
              </h5>
              <small>Validade</small>
            </div>
          </div>

        </div>

      </b-col>
    </b-row>

    <b-row>

      <b-col
        cols="12"
        xl="6"
      >

        <b-img
          thumbnail
          fluid
          :src="campanha.arquivo"
        />

      </b-col>

      <b-col
        cols="12"
        xl="6"
      >
        <h2>Descrição</h2>
        {{ campanha.descricao }}

      </b-col>

    </b-row>

  </b-card>
</template>

<script>
import {
  BImg, BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
// import useUsersList from '../users-list/useUsersList'

// import AudioPlayer from '@liripeng/vue-audio-player'
// import VueAudio from 'vue-audio';

import moment from 'moment'

export default {
  components: {
    BImg, BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    campanha: {
      type: Object,
      required: true,
    },
  },
  methods: {

    formatarData(date) {
      return moment(date).format('DD/MM/YYYY HH:mm')
    },

  },

}
</script>

<style lang="scss">

</style>
